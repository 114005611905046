
.common-bottom-dialog {
  .q-dialog__backdrop {
    background: rgba(0, 0, 0, 0.5);
  }

  .bottom-dialog-outer {
    padding: 1.25rem 0 2.5rem;
    border-radius: 1.25rem 1.25rem 0 0;

    @at-root body.responsive-device & {
      padding: 1.25rem 2rem 2.5rem;
    }
  }

  .bottom-dialog-title {
    padding: 1.25rem 0;
  }

  .bottom-dialog-comment {
    padding-bottom: 1.25rem;
  }

  .bottom-dialog-button {
    color: white;
    border-radius: 0.625rem;
    background: linear-gradient(180deg, #008aff 26.56%, #0069c2 100%);
    box-shadow: 0px 13px 9px 0px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none;
    padding: 0.8125rem 1.35rem 0.75rem 1.35rem;
  }

  .dialog-button {
    border-radius: 0.625rem;
    padding: 0.8125rem 0.75rem 0.75rem;

    &.first-button {
      color: #666666;
    }

    &.second-button {
      color: white;
      background: linear-gradient(180deg, #0172f0 26.56%, #0057b9 100%);
      box-shadow: 0px 13px 9px rgba(0, 0, 0, 0.2);
      -webkit-appearance: none;
      margin-left: 0.46875rem;
    }
  }

  .dialog-space {
    width: 0.94rem;
    flex-grow: unset !important;
  }
}
