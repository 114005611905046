$primary: #0172f0;
$secondary: #abd8ff;
$tertiary: #e8f0f8;
$quaternary: #999999;
$quinary: #ececec;
$senary: #666666;

$accent: #9c27b0;
$positive: #6dd0a3;
$negative: #ec6893;
$info: #9013fe;
$warning: #f6c370;

// score color
$excellent: #1a96ff;
$great: #10b972;
$good: #ffc824;
$normal: #f24040;

// category color
$Memory: #10b972;
$Executive: #a660dc;
$Calculation: #1996ff;
$Language: #10afb9;
$Attention: #ff7a00;
$VisuoSpatial: #ff6bcd;
$MemoryBackground: rgba(16, 185, 114, 0.1);
$ExecutiveBackground: rgba(166, 96, 220, 0.1);
$CalculationBackground: rgba(25, 150, 255, 0.1);
$LanguageBackground: rgba(16, 175, 185, 0.1);
$AttentionBackground: rgba(255, 122, 0, 0.1);
$VisuoSpatialBackground: rgba(255, 107, 205, 0.1);

// assessment
$a-primary: #2c4367;
$a-secondary: #6e859d;
$a-tertiary: #799cab;
$a-quaternary: #0089ff;

$dark: #ffffff;
$dark-page: #ffffff;
