@import 'quasar.variables';

.text-tertiary {
  color: $tertiary;
}
.text-quaternary {
  color: $quaternary;
}
.text-quinary {
  color: $quinary;
}
.text-senary {
  color: $senary;
}

.text-excellent {
  color: $excellent;
}
.text-great {
  color: $great;
}
.text-good {
  color: $good;
}
.text-normal {
  color: $normal;
}

.text-Memory {
  color: $Memory;
}
.text-ExecutiveFunction {
  color: $Executive;
}
.text-Calculation {
  color: $Calculation;
}
.text-Language {
  color: $Language;
}
.text-Attention {
  color: $Attention;
}
.text-VisuoSpatial {
  color: $VisuoSpatial;
}

.text-a-primary {
  color: $a-primary;
}
.text-a-secondary {
  color: $a-secondary;
}
.text-a-tertiary {
  color: $a-tertiary;
}
.text-a-quaternary {
  color: $a-quaternary;
}

.bg-tertiary {
  background-color: $tertiary;
}
.bg-quaternary {
  background-color: $quaternary;
}
.bg-quinary {
  background-color: $quinary;
}
.bg-senary {
  background-color: $senary;
}

.bg-excellent {
  background-color: $excellent;
}
.bg-great {
  background-color: $great;
}
.bg-good {
  background-color: $good;
}
.bg-normal {
  background-color: $normal;
}

.bg-Memory {
  background-color: $MemoryBackground;
}
.bg-ExecutiveFunction {
  background-color: $ExecutiveBackground;
}
.bg-Calculation {
  background-color: $CalculationBackground;
}
.bg-Language {
  background-color: $LanguageBackground;
}
.bg-Attention {
  background-color: $AttentionBackground;
}
.bg-VisuoSpatial {
  background-color: $VisuoSpatialBackground;
}

.bg-a-primary {
  background-color: $a-primary;
}
.bg-a-secondary {
  background-color: $a-secondary;
}
.bg-a-tertiary {
  background-color: $a-tertiary;
}
.bg-a-quaternary {
  background-color: $a-quaternary;
}
