
.loading-outer {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
}
.loading-lottie {
  width: 5rem;
}
