@font-face {
  font-family: "SFPro";
  src: local("SFPRO_REGULAR.OTF"),
    url("/css/fonts/SF_Pro_Regular.woff") format("woff"),
    url("/css/fonts/SF_Pro_Regular.woff2") format("woff2"),
    url("/css/fonts/SF_Pro_Regular.ttf") format("ttf");
  font-display: swap;
}

.SFPro {
  font-family: "SFPro", "Roboto", "-apple-system", sans-serif, Arial;
}

.text-4xl-b {
  // size 40px, height 60px, spacing 0%, bold
  font-size: 2.5rem !important;
  line-height: 3.75rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.88rem !important;
    line-height: 4.95rem !important;
  }
}

.text-3xl-b {
  // size 32px, height 48px, spacing 0%, bold
  font-size: 2rem !important;
  line-height: 3rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.7rem !important;
    line-height: 4.05rem !important;
  }
}

.text-3xl {
  // size 32px, height 48px, spacing 0%, bold
  font-size: 2rem !important;
  line-height: 2.8125rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 2.52rem !important;
    line-height: 3.78rem !important;
  }
}

.text-2xl-b {
  // size 28px, height 42px, spacing 0%, bold
  font-size: 1.75rem !important;
  line-height: 2.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.41rem !important;
    line-height: 3.78rem !important;
  }
}

.text-xl-b-3 {
  // size 26px, height 39px, spacing 0%, bold
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.27rem !important;
    line-height: 3.6rem !important;
  }
}

.text-xl-b {
  // size 26px, height 39px, spacing 0%, bold
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.27rem !important;
    line-height: 3.6rem !important;
  }
}

.text-l-b-3 {
  // size 24px, height 32px, spacing -3%, bold
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.16rem !important;
    line-height: 3.38rem !important;
  }
}

.text-l-b {
  // size 24px, height 32px, spacing 0%, bold
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.16rem !important;
    line-height: 3.38rem !important;
  }
}

.text-l-3 {
  // size 24px, height 32px, spacing -3%, bold
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 2.16rem !important;
    line-height: 3.38rem !important;
  }
}

.text-l {
  // size 24px, height 36px, spacing 0%
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 2.16rem !important;
    line-height: 3.38rem !important;
  }
}

.text-m-b {
  // size 22px, height 33px, spacing 0%, bold
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 2.05rem !important;
    line-height: 3.06rem !important;
  }
}

.text-m {
  // size 22px, height 33px, spacing 0%
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 2.05rem !important;
    line-height: 3.06rem !important;
  }
}

.text-s-b {
  // size 20px, height 30px, spacing 0%, bold
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 1.91rem !important;
    line-height: 2.88rem !important;
  }
}

.text-s-3 {
  // size 20px, height 24px, spacing -3%
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.91rem !important;
    line-height: 2.88rem !important;
  }
}

.text-s {
  // size 20px, height 30px. spacing 0%
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.91rem !important;
    line-height: 2.88rem !important;
  }
}

.text-xs-b {
  // size 18px, height 27px, spacing 0%, bold
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 1.8rem !important;
    line-height: 2.97rem !important;
  }
}

.text-xs-b-3 {
  // size 18px, height 27px, spacing -3%, bold
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 1.8rem !important;
    line-height: 2.97rem !important;
  }
}

.text-xs {
  // size 18px, height 27px, spacing 0%
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.8rem !important;
    line-height: 2.97rem !important;
  }
}

.text-xs-3 {
  // size 18px, height 27px, spacing -3%
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.8rem !important;
    line-height: 2.97rem !important;
  }
}

.text-2xs-b {
  // size 16px, height 27px, spacing 0%
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 1.62rem !important;
    line-height: 2.84rem !important;
  }
}

.text-2xs-3 {
  // size 16px, height 27px, spacing -3%
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.62rem !important;
    line-height: 2.84rem !important;
  }
}

.text-2xs {
  // size 16px, height 27px, spacing -3%
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.62rem !important;
    line-height: 2.84rem !important;
  }
}

.text-3xs-b {
  // size 14px, height 27px, spacing -3%
  font-size: 0.875rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;

  @at-root body.responsive-device & {
    font-size: 1.44rem !important;
    line-height: 2.7rem !important;
  }
}

.text-3xs {
  // size 14px, height 27px, spacing -3%
  font-size: 0.875rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;

  @at-root body.responsive-device & {
    font-size: 1.44rem !important;
    line-height: 2.7rem !important;
  }
}
